import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  useDisableTwoFactorAuthenticationMutation,
  useSuspendUserMutation,
  useUnlockUserMutation,
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import * as styles from './UserActions.module.scss';

export interface Props {
  deletable: boolean;
  locked: boolean;
  setDeleteClickedState: (deleteClicked: boolean) => void;
  suspended: boolean;
  twoFactorEnabled: boolean;
  userId: string;
}

const disableMessages = {
  loading: 'Disabling two-factor authentication',
  error: 'An error occurred while disabling two-factor authentication',
  success: 'Two-factor authentication disabled for the user',
};

const suspendMessages = {
  loading: 'Suspending',
  error: 'An error occurred while suspending the user',
  success: 'User suspended',
};

const unsuspendMessages = {
  loading: 'Unsuspending',
  error: 'An error occurred while unsuspending the user',
  success: 'User unsuspended',
};

const unlockMessages = {
  loading: 'Unlocking',
  error: 'An error occurred while unlocking the user',
  success: 'User is now unlocked',
};

const UserActions: React.FC<Props> = ({
  deletable,
  suspended,
  twoFactorEnabled,
  userId,
  locked,
  setDeleteClickedState,
}) => {
  const mutationOptions = {
    variables: { userId },
  };

  const [disableTwoFactorAuthentication, disableTwoFactorAuthenticationState] =
    useDisableTwoFactorAuthenticationMutation(mutationOptions);

  const [suspendUser, suspendUserState] =
    useSuspendUserMutation(mutationOptions);

  const [unlockUser, unlockUserState] = useUnlockUserMutation(mutationOptions);

  const handleDeletedUser = () => {
    setDeleteClickedState(true);
  };

  const handleDisableTwoFactorAuthentication = () => {
    callMutationWithToastMessages(
      disableTwoFactorAuthentication,
      disableMessages,
    );
  };

  const handleSuspendUser = () => {
    const messages = suspended ? unsuspendMessages : suspendMessages;
    callMutationWithToastMessages(suspendUser, messages);
  };

  const handleUnlockUser = () => {
    callMutationWithToastMessages(unlockUser, unlockMessages);
  };

  const loading =
    disableTwoFactorAuthenticationState.loading ||
    suspendUserState.loading ||
    unlockUserState.loading;

  return (
    <div data-testid="userActions">
      <ToggleableTooltip
        data-testid="toggleable-tooltip"
        tooltipBoxClassName={styles.tooltipBox}
        triggerType="button"
        triggerText="Actions"
        placement="bottomEnd"
      >
        <Link to={`/users/${userId}/add_credit`}>Add Credit</Link>
        <button
          type="button"
          className={`${styles.actionButton} textLink`}
          disabled={loading}
          onClick={() => handleSuspendUser()}
        >
          {suspended ? 'Unsuspend User' : 'Suspend User'}
        </button>
        {twoFactorEnabled && (
          <button
            type="button"
            className={`${styles.actionButton} textLink`}
            disabled={loading}
            onClick={() => handleDisableTwoFactorAuthentication()}
          >
            Disable Two-Factor Authentication
          </button>
        )}
        {locked && (
          <button
            type="button"
            className={`${styles.actionButton} textLink`}
            disabled={loading}
            onClick={() => handleUnlockUser()}
          >
            Unlock User
          </button>
        )}
        <button
          type="button"
          className={`${styles.actionButton} textLink`}
          disabled={loading || !deletable}
          onClick={() => handleDeletedUser()}
        >
          Delete
        </button>
      </ToggleableTooltip>
    </div>
  );
};

export default UserActions;
