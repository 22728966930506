import React from 'react';

interface UserStatus {
  confirmed?: boolean;
  deleted?: boolean;
  suspended?: boolean;
}

export const getUserStatus = (user: UserStatus) => {
  if (user.deleted) return <span className="textRed">Deleted</span>;
  if (user.suspended) return <span className="textRed">Suspended</span>;
  if (user.confirmed) return <span className="textGreen">Active</span>;
  return <span className="textOrange">Pending</span>;
};
