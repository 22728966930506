import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { createAndClickDownloadLink } from 'utils/download';

import { UserDocument } from '../../../../__generated__';

export interface Props {
  document: UserDocument | null;
  setSelectedDocument: (document: UserDocument | null) => void;
}

const DocumentActions: React.FC<Props> = ({
  setSelectedDocument,
  document,
}) => {
  const { filename, url } = document;

  return (
    <div data-testid={`kebab-${document?.id}`}>
      <ToggleableTooltip triggerType="kebab" placement="leftStart">
        <button
          onClick={() => createAndClickDownloadLink(filename, url)}
          type="button"
          className="textLink"
        >
          Download
        </button>
        <button
          onClick={() => setSelectedDocument(document)}
          type="button"
          className="textLink"
        >
          Delete
        </button>
      </ToggleableTooltip>
    </div>
  );
};

export default DocumentActions;
