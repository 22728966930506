import * as yup from 'yup';

import {
  AnnualReturnLabel,
  AssetClass,
  AssetType,
  InterestDayCountConvention,
  InvestmentStrategy,
  OfferingEntityType,
  OfferingInput,
  OfferingPillar,
  OfferingStrategy,
  OfferingType,
  OriginationFeePaidBy,
  PreferredReturnLabel,
  ProjectStage,
  RestrictedTo,
  TypeOffering,
} from '../../../__generated__';
import { File, SchemaShape } from '../../../types/yup';
import { fileTypes, maxUploadSize } from '../../../utils/constants';
import {
  date,
  dateTime,
  twoDigitNumberWithDecimals,
  yupBool,
  yupNumber,
  yupString,
} from '../../../utils/validations';
import { equitySubtypeOptions, otherSubtypeOptions } from './constants';

const equitySubtypeValues = equitySubtypeOptions.map((option) => option.value);
const otherSubtypeValues = otherSubtypeOptions.map((option) => option.value);

export const offeringFormSchema = yup.object<SchemaShape<OfferingInput>>({
  campaign: yupString.when('hasDrip', {
    is: (val: boolean) => val === true,
    then: () => yupString.required('Campaign is required'),
  }),
  investorPacketUrl: yupString.when('omcmsId', {
    is: (val: string | null) => val !== null && val !== 'Disconnect',
    then: () => yupString.required('Investor packet is required'),
  }),
  addendumDocumentUrl: yupString.when('omcmsId', {
    is: (val: string | null) => val !== null && val !== 'Disconnect',
    then: () => yupString.required('Addendum document is required'),
  }),
  signRequiredDocuments: yupString.when('omcmsId', {
    is: (val: string | null) => val !== null && val !== 'Disconnect',
    then: () =>
      yup
        .array(yupString)
        .required('One or more required document required for signing')
        .test(
          'is defined and have value',
          'One or more required document required for signing',
          (val) => val && val.length > 0,
        ),
  }),
  alternateLocation: yupString,
  sortWeight: yupNumber
    .required('Offering card sort weight is required')
    .typeError('Offering card sort weight is required and must be a number'),
  pillar: yupString
    .oneOf(Object.values(OfferingPillar), 'Offering pillar is required')
    .required('Offering pillar is required'),
  offeringStrategy: yup
    .array()
    .test(
      'is defined and a strategy',
      'Offering strategy is required',
      (val) => {
        if (val && val.length > 0) {
          const offeringStrategyValues = Object.values(OfferingStrategy);
          return val.every((arrayVal) =>
            offeringStrategyValues.includes(arrayVal),
          );
        }
        return false;
      },
    ),
  offeringType: yupString
    .oneOf(Object.values(OfferingType), 'Offering type is required')
    .required('Offering type is required'),
  investmentStrategy: yupString
    .oneOf(Object.values(InvestmentStrategy), 'Investment strategy is required')
    .required('Investment strategy is required'),
  assetType: yupString
    .oneOf(Object.values(AssetType), 'Asset type is required')
    .required('Asset type is required'),
  projectStage: yupString
    .oneOf(Object.values(ProjectStage), 'Project stage is required')
    .required('Project stage is required'),
  interestDayCountConvention: yupString
    .oneOf(
      Object.values(InterestDayCountConvention),
      'Interest day count convention is required',
    )
    .required('Interest day count convention is required'),
  investmentIncrement: yupNumber
    .notRequired()
    .typeError('Investment increment must be a number'),
  firstTimeInvestorMinimumAmount: yupNumber
    .notRequired()
    .when('investmentIncrement', {
      is: (val: number | null) => val !== null,
      then: () =>
        yupNumber.test(
          'must be multiple of investmentIncrement',
          'First time investor minimum amount must be a multiple of Investment Increment',
          function (val) {
            const { investmentIncrement } = this.parent;
            return !isNaN(val) && val % investmentIncrement === 0;
          },
        ),
    }),
  partialShares: yupBool,
  redemptionPeriod: yupString,
  annualReturnLabel: yupString.oneOf(
    Object.values(AnnualReturnLabel),
    'Annual return label is invalid',
  ),
  projectedAnnualReturn: yupString,
  originationFeePaidBy: yupString.oneOf(
    Object.values(OriginationFeePaidBy),
    'Origination fee paid by is invalid',
  ),
  originationFeeRate: twoDigitNumberWithDecimals,
  referralBonusRate: twoDigitNumberWithDecimals,
  assetManagementRate: twoDigitNumberWithDecimals,
  preferredReturnLabel: yupString.oneOf(
    Object.values(PreferredReturnLabel),
    'Preferred return label is invalid',
  ),
  ltv: yupString,
  showLtc: yupBool,
  lienPosition: yupString,
  cardImage: yup
    .mixed<File>()
    .test(
      'File Size',
      'Card image is too large',
      (file) => !file || !file.size || (file && file.size <= maxUploadSize),
    )
    .test(
      'File Type',
      'Card image must be in JPG or PNG format',
      (file) =>
        !file ||
        !file.type ||
        (file &&
          fileTypes.map((fileType) => fileType.format).includes(file.type)),
    ),
  confidentialityRequired: yupBool,
  confidentialityStatus: yupString.when('confidentialityRequired', {
    is: true,
    then: () => yupString.required('Confidentiality agreement is required'),
  }),
  disableCard: yupBool,
  excludeFromTrackrecord: yupBool,
  customInstructionsEnabled: yupBool,
  customFundingInstructions: yupString.when('customInstructionsEnabled', {
    is: true,
    then: () => yupString.required('Custom funding instructions are required'),
  }),
  customFundingNextSteps: yupString.when('customInstructionsEnabled', {
    is: true,
    then: () => yupString.required('Next steps are required'),
  }),
  disableWaitlistPledges: yupBool,
  showProgress: yupBool,
  showViewCount: yupBool,
  showInvestorPacket: yupBool,
  acceptsCredits: yupBool,
  sponsorId: yupString.required('Sponsor is required'),
  entityName: yupString,
  entityType: yupString
    .oneOf(Object.values(OfferingEntityType), 'Entity type is invalid')
    .notRequired(),
  type: yupString
    .oneOf(Object.values(TypeOffering), 'Type is invalid')
    .notRequired(),
  assetClass: yupString
    .oneOf(Object.values(AssetClass), 'Asset class is invalid')
    .notRequired(),
  subtype: yupString
    .oneOf(otherSubtypeValues, 'Offering subtype is invalid')
    .when('offeringType', {
      is: (val: OfferingType) =>
        ['equity', 'preferred_equity', 'senior_equity'].includes(val),
      then: () =>
        yupString.oneOf(equitySubtypeValues, 'Offering subtype is invalid'),
    })
    .notRequired(),
  restrictedTo: yupString
    .oneOf(Object.values(RestrictedTo), 'Restricted to is invalid')
    .notRequired(),
  description: yupString,
  closedOn: dateTime,
  debtMaturityDate: date.when('appendBpdn', {
    is: (val: boolean) => val === true,
    then: () =>
      date.required('Debt maturity date is required when appending BPDN'),
  }),
  debtExtensionRate: twoDigitNumberWithDecimals.when('appendBpdn', {
    is: (val: boolean) => val === true,
    then: () =>
      twoDigitNumberWithDecimals
        .required('Debt extension rate is required when appending BPDN')
        .positive('Debt extension rate is required when appending BPDN'),
  }),
});
