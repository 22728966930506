import { Button, Select } from '@equitymultiple/react-eui';
import { NonAsyncValue } from '@equitymultiple/react-eui/dist/components/Select/Select';
import Option from '@equitymultiple/react-eui/dist/types/Option';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';

import {
  DwollaCustomer,
  DwollaFundingSource,
  useEditOfferingBankAccountMutation,
  useGetDwollaFundingSourcesLazyQuery,
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../../utils/formHelpers';
import { bankAccountSchema } from '../validation';

interface BankAccountInfo {
  dwollaCustomerId?: string;
  dwollaFundingSourceId?: string;
}

interface Props {
  bankAccountInfo?: BankAccountInfo;
  dwollaCustomers: DwollaCustomer[];
  id: string;
}

type FormValues = BankAccountInfo;

const messages = {
  loading: 'Updating',
  error: 'An error occurred while updating bank account information',
  success: 'Bank account information updated',
};

const AttachBankAccount: React.FC<Props> = ({
  bankAccountInfo,
  dwollaCustomers,
  id,
}) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(bankAccountSchema),
    defaultValues: {
      ...bankAccountInfo,
    },
  });
  const [
    getFundingSources,
    {
      data: fundingSourcesData,
      loading: fundingSourcesLoading,
      refetch,
      called,
    },
  ] = useGetDwollaFundingSourcesLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [editOfferingBankAccount, editOfferingBankAccountState] =
    useEditOfferingBankAccountMutation();
  const { loading } = editOfferingBankAccountState;
  const [fundingSources, setFundingSources] = useState<DwollaFundingSource[]>(
    [],
  );
  const { dwollaCustomerId } = getValues();

  useEffect(() => {
    // Load funding sources if a customer ID is already saved when the page loads
    if (dwollaCustomerId && !called)
      getFundingSources({ variables: { dwollaCustomerId } });

    // Set funding sources whenever the customer is changed and new sources are loaded
    if (fundingSourcesData?.dwollaFundingSources.dwollaFundingSources)
      setFundingSources(
        fundingSourcesData?.dwollaFundingSources
          .dwollaFundingSources as DwollaFundingSource[],
      );
  }, [dwollaCustomerId, fundingSourcesData, getFundingSources, called]);

  let customerOptions: Option[] = [];
  if (dwollaCustomers) {
    customerOptions = dwollaCustomers.map((customer) => ({
      label: customer.businessName as string,
      value: customer.id as string,
    }));
  }

  let bankAccountOptions: Option[] = [];
  if (fundingSources) {
    bankAccountOptions = fundingSources.map((fundingSource) => {
      let label =
        fundingSource.name ||
        fundingSource.bankAccount?.name ||
        '[Unnamed Bank Account]';
      if (fundingSource.type === 'balance') label = 'Balance';
      label += ` (${fundingSource.status})`;

      return {
        label,
        value: fundingSource.id as string,
      };
    });
  }

  const handleCustomerChange = (customerId: NonAsyncValue) => {
    const variables = {
      dwollaCustomerId: customerId as string,
    };
    if (!called) getFundingSources({ variables });
    else refetch(variables);
    setValue('dwollaCustomerId', customerId as string);
  };

  const handleBankAccountChange = (bankAccountId: NonAsyncValue) => {
    setValue('dwollaFundingSourceId', bankAccountId as string);
  };

  const onSubmit = (values: FormValues) => {
    callMutationWithToastMessages(editOfferingBankAccount, messages, {
      variables: {
        offering: {
          id,
          ...values,
        },
      },
    });
  };

  let bankAccountHelperText = '';
  // Loading bank accounts
  if (fundingSourcesLoading) bankAccountHelperText = 'Loading bank accounts...';
  // Loaded, no bank accounts found
  else if (dwollaCustomerId && !fundingSources.length)
    bankAccountHelperText = 'No bank accounts found';
  // Customer not yet selected
  else if (!dwollaCustomerId)
    bankAccountHelperText = 'Select a customer to see bank accounts';

  return (
    <form
      className="clearFix"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="bankForm"
    >
      <h3>Attach a Bank Account for Investments & Distributions</h3>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="dwollaCustomerId"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={customerOptions}
                onChange={handleCustomerChange}
                label="Customer"
                disabled={fundingSourcesLoading}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="dwollaFundingSourceId"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={bankAccountOptions}
                onChange={handleBankAccountChange}
                label="Bank Account"
                disabled={fundingSourcesLoading}
                helperText={bankAccountHelperText}
                helperTextAlwaysVisible
              />
            )}
          />
        </Col>
      </Row>

      <Button type="submit" className="floatRight" loading={loading}>
        Save Bank Account Info
      </Button>
    </form>
  );
};

export default AttachBankAccount;
