import {
  Button,
  EMLoadingIcon,
  FileUploader,
  Select,
} from '@equitymultiple/react-eui';
import { NonAsyncValue } from '@equitymultiple/react-eui/dist/components/Select/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import {
  DocumentType,
  DwollaCustomer,
  DwollaCustomerDocumentInput,
  namedOperations,
  useUploadDwollaCustomerDocumentMutation,
} from '../../../../__generated__';
import pdfIcon from '../../../../assets/icons/pdf.svg?url';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../../utils/formHelpers';
import { documentIdTypes } from '../../constants';
import * as styles from '../AdminAccounts.module.scss';
import { editIdentityDocumentSchema } from '../validation';

export interface Props {
  dwollaCustomer: DwollaCustomer;
  loading: boolean;
}

type DwollaCustomerDocumentType = Extract<
  DocumentType,
  | DocumentType.Passport
  | DocumentType.IdCard
  | DocumentType.License
  | DocumentType.Other
>;

const messages = {
  loading: 'Adding document',
  error: 'An error occurred while adding the document',
  success: 'Document added',
};

const IdentityDocument: React.FC<Props> = ({ dwollaCustomer, loading }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    getValues,
  } = useForm<DwollaCustomerDocumentInput>({
    mode: 'onBlur',
    resolver: yupResolver(editIdentityDocumentSchema),
  });

  const showDocumentSection =
    loading ||
    dwollaCustomer?.status === 'retry' ||
    dwollaCustomer?.status === 'document' ||
    dwollaCustomer?.document?.id;

  const [uploadDwollaCustomerDocument, uploadDwollaCustomerDocumentState] =
    useUploadDwollaCustomerDocumentMutation();

  const onSubmit = async (
    submitData: Required<DwollaCustomerDocumentInput>,
  ) => {
    const mutationOptions = {
      documentType: submitData.documentType,
      file: submitData.file,
      dwollaCustomerId: dwollaCustomer.id as string,
    };
    callMutationWithToastMessages(uploadDwollaCustomerDocument, messages, {
      variables: {
        documentInput: mutationOptions,
      },
      refetchQueries: [namedOperations.Query.getDwollaCustomer],
    });
  };

  const documentType = (docType: DwollaCustomerDocumentType) => {
    if (docType === 'idCard') {
      return 'ID Card';
    } else {
      return docType;
    }
  };

  let acceptedFileTypes: string[] =
    getValues('documentType') === 'other'
      ? ['JPG', 'PNG', 'PDF']
      : ['JPG', 'PNG'];

  const onDocumentTypeChange = (
    field: ControllerRenderProps<DwollaCustomerDocumentInput, 'documentType'>,
    value: NonAsyncValue,
  ) => {
    acceptedFileTypes =
      value === 'other' ? ['JPG', 'PNG', 'PDF'] : ['JPG', 'PNG'];
    clearErrors('file');
    field.onChange(value);
  };

  const fileExtension = dwollaCustomer?.document?.filename
    ?.toLowerCase()
    .split('.')
    .pop();

  return showDocumentSection ? (
    <>
      <hr />
      <h3 data-testid="addIdentityDocument">Add Identity Document</h3>
      {loading ? (
        <div className={styles.loadingIconSection}>
          <EMLoadingIcon className={styles.loadingIcon} />
        </div>
      ) : (
        <form
          data-testid="uploadDocumentForm"
          onSubmit={handleSubmit(onSubmit)}
          className="clearFix"
        >
          {dwollaCustomer.document?.url && (
            <Col className="margin20" lg={4} md={6}>
              <h4>Previously Uploaded</h4>
              <p>
                Document Type: {/* span added for testing purposes */}
                <span>
                  {documentType(
                    dwollaCustomer.document?.type as DwollaCustomerDocumentType,
                  )}
                </span>
              </p>

              {fileExtension === 'pdf' ? (
                <div className={styles.pdfContainer}>
                  <img src={pdfIcon} alt="PDF icon" />

                  <Link
                    to={dwollaCustomer.document.url}
                    target={'_blank'}
                    data-testid="pdfLink"
                  >
                    {dwollaCustomer.document.filename}
                  </Link>
                </div>
              ) : (
                <img
                  className={styles.previouslyUploadedDocument}
                  src={dwollaCustomer.document.url as string}
                  alt="Previously uploaded document"
                />
              )}
            </Col>
          )}

          <Row>
            <Col lg={4} md={6}>
              <Controller
                name="documentType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    onChange={(value) => onDocumentTypeChange(field, value)}
                    label="Document Type"
                    id="documentType"
                    options={documentIdTypes}
                  />
                )}
              />
            </Col>
          </Row>
          <Col lg={6} md={8}>
            <Controller
              name="file"
              control={control}
              render={({ field }) => (
                <FileUploader
                  {...setFieldProps(field, errors)}
                  showImage={true}
                  existingFile={field.value}
                  upload={(uploadData: File) => field.onChange(uploadData)}
                  onRemove={() => field.onChange(null)}
                  acceptedFileTypes={acceptedFileTypes}
                />
              )}
            />
          </Col>
          <Button
            className="floatRight"
            type="submit"
            loading={uploadDwollaCustomerDocumentState.loading}
          >
            Upload Document
          </Button>
        </form>
      )}
    </>
  ) : null;
};

export default IdentityDocument;
