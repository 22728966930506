import { EMLoadingIcon } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useGetDocumentUrlQuery } from '../../__generated__';

const DocumentDownload: React.FC = () => {
  const id = useParams().id as string;

  const { data, error, loading } = useGetDocumentUrlQuery({
    variables: { id },
  });

  const hasError = error || data?.documentUrl.error?.message;

  useEffect(() => {
    if (hasError) {
      toast.error('Error fetching document URL');
      window.location.replace('/');
    }
  }, [hasError]);

  const downloadUrl = data?.documentUrl.url;

  useEffect(() => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
      window.location.replace('/');
    }
  }, [downloadUrl]);

  return <>{loading ? <EMLoadingIcon data-testid="emLoadingIcon" /> : null}</>;
};

export default DocumentDownload;
