import {
  BankAccount,
  BankAccountInput,
  namedOperations,
  useCreateBankAccountMutation,
  useGetBankAccountsQuery,
} from '__generated__';
import { Button, Input, RadioButton, Select } from '@equitymultiple/react-eui';
import Option from '@equitymultiple/react-eui/dist/types/Option';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import { setFieldProps } from 'utils/formHelpers';

import { bankAccountTypeOptions } from '../../constants';
import * as styles from '../AdminAccounts.module.scss';
import { bankAccountSchema } from '../validation';

const toastMessages = {
  loading: 'Adding bank account',
  error: 'An error occurred while attempting to add new bank account',
  success: 'Bank account added',
};

const defaultValues = {
  accountNumber: '',
  id: '',
  name: '',
  routingNumber: '',
  type: null,
};

const NewBankAccount: React.FC = () => {
  const { id: dwollaCustomerId } = useParams();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DefaultValues<BankAccountInput>>({
    mode: 'onBlur',
    resolver: yupResolver(bankAccountSchema),
    defaultValues,
  });

  const [accountMethod, setAccountMethod] = useState('bankSelect');

  const [bankAccountOptions, setBankAccountOptions] = useState<Option[]>([]);
  const { data } = useGetBankAccountsQuery();

  const adminBankAccounts = data?.bankAccounts.data as BankAccount[];

  useEffect(() => {
    if (adminBankAccounts && adminBankAccounts.length > 0) {
      const bankAccounts = adminBankAccounts.map(
        (bankAccount: BankAccount) => ({
          value: bankAccount.id as string,
          label: `${bankAccount.name} (${bankAccount.accountNumber})`,
        }),
      );
      setBankAccountOptions(bankAccounts);
    }
  }, [adminBankAccounts]);

  const [createBankAccount, createBankAccountState] =
    useCreateBankAccountMutation();

  const loading = createBankAccountState.loading;

  const onSubmit = async (submitData: BankAccountInput) => {
    await callMutationWithToastMessages(createBankAccount, toastMessages, {
      variables: {
        dwollaCustomerId: dwollaCustomerId as string,
        bankAccountInput: {
          id: submitData.id,
          accountNumber: submitData.accountNumber,
          name: submitData.name,
          routingNumber: submitData.routingNumber,
          type: submitData.type,
        },
      },
      refetchQueries: [namedOperations.Query.getDwollaCustomer],
    });
    reset();
  };

  const handleAccountMethodChange = (method: string) => {
    reset();
    setAccountMethod(method);
  };

  return (
    <>
      <h3 data-testid="addAdminAccountHeading">Add New Bank Account</h3>
      <div className="accountMethodOptions margin30">
        <RadioButton
          id="bankSelect"
          name="accountMethod"
          label="Select from existing bank accounts"
          checked={accountMethod === 'bankSelect'}
          onChange={() => handleAccountMethodChange('bankSelect')}
        />
        <RadioButton
          id="bankForm"
          name="accountMethod"
          label="Add using account and routing number"
          onChange={() => handleAccountMethodChange('bankForm')}
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.newBankAccountForm}
        data-testid="addBankAccountForm"
      >
        {accountMethod === 'bankSelect' && (
          <Row>
            <Col lg={4} md={6}>
              <Controller
                name="id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    options={bankAccountOptions}
                    onChange={(e) => field.onChange(e)}
                    label="Select Bank Account"
                  />
                )}
              />
            </Col>
          </Row>
        )}
        {accountMethod === 'bankForm' && (
          <div>
            <Row>
              <Col lg={4} md={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input {...setFieldProps(field, errors)} label="Name" />
                  )}
                />
              </Col>

              <Col lg={4} md={6}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...setFieldProps(field, errors)}
                      options={bankAccountTypeOptions}
                      onChange={(e) => field.onChange(e)}
                      label="Account Type"
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={6}>
                <Controller
                  name="routingNumber"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...setFieldProps(field, errors)}
                      mask="999999999"
                      label="Routing Number"
                    />
                  )}
                />
              </Col>
              <Col lg={4} md={6}>
                <Controller
                  name="accountNumber"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...setFieldProps(field, errors)}
                      label="Account Number"
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
        )}
        <Button className="floatRight" type="submit" loading={loading}>
          Add Bank Account
        </Button>
      </form>
    </>
  );
};

export default NewBankAccount;
