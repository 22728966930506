import { Button } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  AccountHolder,
  EditInvestmentAccountInput,
  InvestmentAccount,
  InvestmentAccountType,
  useEditInvestmentAccountMutation,
  User,
  useRetryCustomerMutation,
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setDefaultValues } from '../../../../utils/formHelpers';
import FieldsEntity from './FieldsEntity';
import FieldsIndividual from './FieldsIndividual';
import FieldsIra from './FieldsIra';
import FieldsJoint from './FieldsJoint';
import * as styles from './InvestmentAccountEdit.module.scss';
import {
  entityAccountFormSchema,
  individualAccountFormSchema,
  iraAccountFormSchema,
  jointAccountFormSchema,
} from './validation';

interface Props {
  account: InvestmentAccount;
}

type FormValues = EditInvestmentAccountInput;

const messages = {
  loading: 'Updating account',
  error: 'An error occurred while updating the investment account',
  success: 'Account updated',
};

const retryMessages = {
  loading: 'Submitting Dwolla retry request',
  error: 'An error occurred while submitting retry request to Dwolla',
  success: 'Dwolla retry submitted',
};

const getDefaultValues = (account: InvestmentAccount) => {
  const {
    subType,
    entityName,
    businessType,
    iraAccountFboName,
    iraAccountNumber,
    iraRoutingNumber,
    ein,
    id,
    jurisdictionOfBusinessRegistration,
    dateOfFormation,
    dateOfRegistration,
    businessClass,
    industryClass,
    officerTitle,
  } = account;
  const user = account.user as User;
  const accountHolder2 = account.accountHolder2 as AccountHolder;
  const {
    address: { address, address2, city, state, postalCode },
    investorProfile: { dob, ssn },
    phone,
    firstName,
    lastName,
  } = user;

  let fields: EditInvestmentAccountInput = {
    id: id as string,
  };

  switch (account.type) {
    case 'individual':
      fields = {
        ...fields,
        user: {
          dob,
          ssn: ssn || '',
          firstName: firstName || '',
          lastName: lastName || '',
          phone: phone || '',
          address: address || '',
          address2: address2 || '',
          city: city || '',
          state,
          postalCode: postalCode || '',
        },
      };
      break;
    case 'entity':
      fields = {
        ...fields,
        entityName: entityName || '',
        businessType,
        ein: ein || '',
        jurisdictionOfBusinessRegistration,
        dateOfFormation,
        dateOfRegistration,
        businessClass,
        industryClass,
        address: account.address || '',
        city: account.city || '',
        region: account.region,
        postalCode: account.postalCode || '',
        officerTitle: officerTitle || '',
        user: {
          firstName: firstName || '',
          lastName: lastName || '',
          phone: phone || '',
          dob,
          ssn: ssn || '',
        },
      };
      break;
    case 'joint_account':
      fields = {
        ...fields,
        subType,
        user: {
          phone: phone || '',
          dob,
          ssn: ssn || '',
          address: address || '',
          address2: address2 || '',
          city: city || '',
          state,
          postalCode: postalCode || '',
        },
        accountHolder2: {
          firstName: accountHolder2.firstName || '',
          lastName: accountHolder2.lastName || '',
          dob: accountHolder2.dob,
          email: accountHolder2.email || '',
          ssn: accountHolder2.ssn || '',
          addressIsSame: accountHolder2.addressIsSame,
          address: accountHolder2.address || '',
          address2: accountHolder2.address2 || '',
          city: accountHolder2.city || '',
          postalCode: accountHolder2.postalCode || '',
          region: accountHolder2.region,
        },
      };
      if (fields.accountHolder2)
        delete (fields.accountHolder2 as AccountHolder).__typename;
      break;
    case 'ira':
      fields = {
        ...fields,
        entityName: entityName || '',
        businessType,
        iraAccountNumber: iraAccountNumber || '',
        iraAccountFboName: iraAccountFboName || '',
        iraRoutingNumber: iraRoutingNumber || '',
      };
      break;
  }

  return setDefaultValues(fields as Record<string, unknown>);
};

const getAccountSchema = (accountType: InvestmentAccountType) => {
  switch (accountType) {
    case 'entity':
      return entityAccountFormSchema;
    case 'joint_account':
      return jointAccountFormSchema;
    case 'ira':
      return iraAccountFormSchema;
    default:
      return individualAccountFormSchema;
  }
};

const InvestmentAccountEditForm: React.FC<Props> = ({ account }) => {
  const [accountUpdated, setAccountUpdated] = useState(false);
  let accountDefaultValues;
  let accountSchema = individualAccountFormSchema;
  if (account) {
    accountDefaultValues = getDefaultValues(account);
    accountSchema = getAccountSchema(account.type as InvestmentAccountType);
  }

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(accountSchema),
    defaultValues: accountDefaultValues,
  });
  const [editInvestmentAccount, editInvestmentAccountState] =
    useEditInvestmentAccountMutation();
  const [retryCustomer, retryCustomerState] = useRetryCustomerMutation();

  const { loading } = editInvestmentAccountState;
  const { loading: submitting } = retryCustomerState;

  const onSubmit = (values: FormValues) => {
    callMutationWithToastMessages(editInvestmentAccount, messages, {
      variables: {
        investmentAccount: {
          ...values,
        },
      },
    }).then(() => setAccountUpdated(true));
  };

  const submitRetryCustomer = () => {
    callMutationWithToastMessages(retryCustomer, retryMessages, {
      variables: {
        investmentAccountId: account?.id,
      },
    });
  };

  const getAccountFields = () => {
    const fieldProps = { account, control, errors, watch };
    switch (account?.type) {
      case 'individual':
        return <FieldsIndividual {...fieldProps} />;
      case 'joint_account':
        return <FieldsJoint {...fieldProps} watch={watch} />;
      case 'entity':
        return (
          <FieldsEntity {...fieldProps} watch={watch} setValue={setValue} />
        );
      case 'ira':
        return <FieldsIra {...fieldProps} />;
      default:
        return null;
    }
  };

  return (
    <form
      className="clearFix"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="investmentAccountForm"
    >
      {getAccountFields()}
      {account?.dwollaCustomer?.status === 'retry' && (
        <Button
          variant="orange"
          className={`floatRight ${styles.retryButton}`}
          loading={submitting}
          disabled={!accountUpdated}
          onClick={submitRetryCustomer}
        >
          Submit Retry Request
        </Button>
      )}
      <Button type="submit" className="floatRight" loading={loading}>
        Save Account
      </Button>
    </form>
  );
};

export default InvestmentAccountEditForm;
