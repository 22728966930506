import { Button, Card, Input, Select } from '@equitymultiple/react-eui';
import Option from '@equitymultiple/react-eui/dist/types/Option';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import formatCurrency from 'utils/formatCurrency';
import { investmentAccountTypeLabels } from 'views/Accounts/constants';

import {
  CreditableInvestment,
  GrantCreditMutationVariables,
  useGetCreditableInvestmentsQuery,
  useGrantCreditMutation,
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../utils/formHelpers';
import * as styles from './AddReferralCredit.module.scss';
import { addReferralCreditSchema } from './validation';

const toastMessages = {
  loading: 'Adding referral credit',
  error: 'An error occurred while attempting to add the referral credit',
  success: 'Referral credit added',
};

const AddReferralCredit: React.FC = () => {
  const userId = useParams().id;
  const navigate = useNavigate();

  const [investmentOptions, setInvestmentOptions] = useState<Option[]>([]);
  const [grantCredit, grantCreditState] = useGrantCreditMutation();
  const {
    data: investmentsData,
    error: investmentsError,
    loading: investmentsLoading,
  } = useGetCreditableInvestmentsQuery({
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const loading = investmentsLoading;
  const submitting = grantCreditState.loading;
  const hasError =
    investmentsError || investmentsData?.creditableInvestments?.error;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(addReferralCreditSchema),
  });

  const onSubmit = async (submitData) => {
    const variables: GrantCreditMutationVariables = {
      userId: userId.toString(),
    };
    if (submitData.inviterId) variables.inviterId = submitData.inviterId;
    if (submitData.investmentId && submitData.investmentId !== 'none')
      variables.investmentId = submitData.investmentId;

    const { data } = await callMutationWithToastMessages(
      grantCredit,
      toastMessages,
      {
        variables,
      },
    );

    if (!data.grantCredit.error) {
      navigate(`/users/${userId}`);
    }
  };

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading eligible investments');
      navigate(`/users`);
    }
  }, [hasError, navigate]);

  const creditableInvestments = investmentsData?.creditableInvestments
    ?.data as CreditableInvestment[];

  useEffect(() => {
    if (creditableInvestments) {
      const none = {
        value: 'none',
        label: 'Do not apply to any investment',
      };
      const investments = creditableInvestments.map(
        (creditableInvestment: CreditableInvestment) => {
          const { investment, offering, investmentAccount } =
            creditableInvestment;

          return {
            label: `${investmentAccount?.entityName} (${investmentAccount?.type ? investmentAccountTypeLabels[investmentAccount.type] : null}), ${offering.title} - ${formatCurrency(investment.amount)} (ID: ${investment.id})`,
            value: investment.id as string,
          };
        },
      );
      setInvestmentOptions([none, ...investments]);
    }
  }, [creditableInvestments]);

  return (
    <>
      <h2 className="contentNarrow">Add Referral Credit</h2>
      <Card className={`contentNarrow firstChildMarginTop0`}>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="sponsorForm">
          <p className="margin30">
            Add a referral credit for user with ID {userId}
          </p>
          <Row>
            <Col lg={4} md={6}>
              <Controller
                name="inviterId"
                control={control}
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Inviter ID (Optional)"
                    value={field.value || ''}
                    type="number"
                    wholeNumberMask
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              {loading ? (
                <div data-testid="skeletonLoader">
                  <Skeleton className={styles.loadingSkeleton} />
                </div>
              ) : (
                <Controller
                  name="investmentId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...setFieldProps(field, errors)}
                      options={investmentOptions}
                      onChange={(e) => field.onChange(e)}
                      label="Apply To Investment (Optional)"
                    />
                  )}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col className="alignItemsCenter" xs={8}>
              <Link type="button" className="textLink" to={`/users/${userId}`}>
                Cancel
              </Link>
            </Col>
            <Col xs={4}>
              <Button type="submit" className="floatRight" loading={submitting}>
                Add Credit
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default AddReferralCredit;
